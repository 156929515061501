.animal-show {
  position: relative;
  border: 1px solid grey;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  box-shadow: 0px 3px 4px lightgrey;
}

.animal {
  height: 100px;
}

.heart {
  position: absolute;
  bottom: 10%;
  right: 10%;
}
