.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
}

button {
  background-color: lightgreen;
  border: 1px solid green;
  border-radius: 3px;
  font-size: 20px;
  padding: 10px;
  width: 30%;
}
.animal-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.subheading {
  margin-bottom: 20px;
}
